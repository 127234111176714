.App {
  text-align: center;
  box-sizing: border-box;
  background-color: #eaeaea;
  color: #333333;
  font-family: 'Lato', sans-serif;
}

body {
  overflow-x: hidden;
  background-color: #eaeaea;
}

#diss-btn, #view-tp, #view-sp, #lesson-form, #bookme-btn, #submit-btn {
  background-color: gray;
  color: white;
  border: 1px solid gray;
  transition: transform 0.2s ease;
}

button:hover {
  transform: scale(1.2);
}

#navbar {
  width: 100%;
  transition: background-color 0.5s ease;
}

.nav-link {
  color: white;
  transform: scale(1);
  transition: transform 0.2s ease;
}

.nav-link:hover {
  color: white;
  transform: scale(1.2);
}

#main-page {
  position: absolute;
  top: 0;
  z-index: 1;
}

.fixed-background {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-attachment: scroll;
}

.fixed-background:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 60%;
  background-repeat: no-repeat;
  background-size: cover;
}

.hp-content-wrapper {
  transform: translateY(-50%);
  transition: transform 0.5s ease;
  text-align: center;
  color: white;
  position: relative;
  top: 17rem;
}

.hp-title {
  margin: 0;
}

.hp-subtitle {
  margin: 10px 0;
}

.overlay-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  text-align: center;
  color: white;
}

.overlay-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.bio-photo {
  width: 100%;
  max-width: 15rem;
  border-radius: 5px;
  margin-bottom: 1.5rem;
}

.bio-photo, .teaching-img-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.teaching-row {
  display: flex;
  justify-content: space-between;
}

.teaching-img-container {
  height: 22rem;
  max-width: 20rem;
  overflow: hidden;
  margin: 2rem auto 1rem auto;
  border-radius: 5px;
}

.teaching-img-container img {
  width: 100%;
  height: 103%;
  object-fit: cover;
  border-radius: 5px;
}

.teaching-card p {
  margin-top: 10px;
  font-size: 1.2rem;
}

.videos-subtitle {
  background-color: #eaeaea;
  padding: 5px 0;
}

.video-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnail {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 30rem;
}

.thumbnail:nth-of-type(3) {
  margin-bottom: 0;
}

.carousel-item {
  position: relative;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer {
  justify-content: center;
  font-size: 0.8rem;
}

.footer-links {
  display: none;
}

.footer-copyright {
  color: grey;
  width: 90%;
  padding: 8px 0;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
}

.modal-closed {
  display: none;
}

.modal-open {
  display: flex;
}

.modal-content {
  margin-top: 4rem;
  padding: 20px;
  width: 90vw;
  height: 80vh;
  overflow-y: scroll;
  background-color: #eaeaea;
  color: #333333;
  z-index: 100 !important;
  position: relative;
}

.modal-content::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 5px;
}

.modal-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333333;
}

#teaching, #samples {
  margin-top: 4rem;
}

.visibility-hidden {
  visibility: hidden;
}

@media (min-width: 520px) {

  .hp-title {
    font-size: 2rem;
  }
  
  .hp-subtitle {
    font-size: 1.2rem;
  }
  
  .fixed-background:before {
    background-attachment: fixed;
  }

  .bio {
    text-align: left;
  }

  .bio h1 {
    text-align: center;
  }

  .bio-photo {
    float: right;
  }
  
  .bio-photo {
    margin-left: 2rem;
  }

  .modal-content {
    width: 70vw;
    max-width: 750px;
  }

}

@media (min-width: 850px) {
  .video-col {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .thumbnail {
    width: 32%;
    max-width: none;
    margin-bottom: 0;
  }

  .sample-videos {
    background-color: black;
    border-bottom: 5px solid black;
  }

}

@media (min-width: 936px) {
  .footer {
    justify-content: space-between;
    font-size: 1rem;
    padding: 1rem;
  }

  .footer-copyright {
    flex-basis: 50%;
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }

  .footer-links .nav-link {
    color: black;
  }

  .footer-copyright {
    text-align: right;
  }
}